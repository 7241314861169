import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router} from "react-router-dom"
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './muiTheme'
import * as serviceWorker from './serviceWorker';

const App = lazy(() => import('./App'))

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Suspense fallback={
          <div style={{width: '100%', height: '100vh', textAlign: 'center', lineHeight: '100vh', fontSize: '32px'}}>
            Loading ...
          </div>
        }>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
