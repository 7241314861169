import { createMuiTheme } from '@material-ui/core/styles';
import { pink, grey } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[800],
    },
    secondary: {
      main: pink[200],
    },
  },
});

export default theme